import {
  Alert,
  Button,
  Icon,
  Item,
  Snackbar,
  Toast,
  TopBar,
} from '@acciona/ui-ionic-kit';
import { isPlatform } from '@ionic/react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { MapReservation } from '../../../../components/MapReservation/MapReservation';
import dayjs from 'dayjs';
import { isEmpty } from '../../../../utils/functions';
import { reservationActions } from '../../../../_redux/actions';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { AppStore } from '../../../../_redux/models/app.model';
import { UserStore } from '../../../../_redux/models/user.model';
import {
  Workroom,
  WorkroomStore,
} from '../../../../_redux/models/workroom.model';
import { reservationServices } from '../../../../_redux/services';
import styles from './styles.module.scss';
import SafeImage from '../../../../components/SafeImage/SafeImage';

const DetailWorkroom: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { workroomDetailReservation, workroomDetail, isLoading } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );

  const { user } = useSelector((state: UserStore) => state.user);
  const [showTimeLeftSnack, setShowTimeLeftSnack] = useState(false);
  const [showErrTimeRoom, setShowErrTimeRoom] = useState(false);
  const [disableCheckIn, setBtnDisableCheckIn] = useState(true);
  const [approvedResponse, setApprovedResponse] = useState(false);
  const [inProgressResponse, setInProgressResponse] = useState(false);
  const [showReservationBtn, setShowReservationBtn] = useState(false);
  const [alertApproved, setAlertApproved] = useState(true);
  const [alertInProgress, setAlertInProgress] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const {
    maxTimeExtendRoom,
    maxTimeCheckInRoom,
    availabilityExtendRoomWhenLeftMinutes,
  } = useSelector((state: AppStore) => state.app.globalSettings);

  useEffect(() => {
    let timerCheckin;
    let timerTmLeft;

    if (
      workroomDetailReservation != undefined &&
      !isEmpty(workroomDetailReservation)
    ) {
      const diffMsCheckin = dayjs(workroomDetailReservation.startDate)
        .tz()
        .diff(dayjs().tz().add(5, 'minute'));
      const diffMsTimeLeft = dayjs(workroomDetailReservation.endDate)
        .tz()
        .diff(
          dayjs().tz().add(availabilityExtendRoomWhenLeftMinutes, 'minute'),
        );

      diffMsCheckin < 0
        ? setBtnDisableCheckIn(false)
        : (timerCheckin = setTimeout(
            () => setBtnDisableCheckIn(false),
            diffMsCheckin > 2147483647 ? 2147483647 : diffMsCheckin,
          ));

      diffMsTimeLeft < 0
        ? setShowTimeLeftSnack(true)
        : (timerTmLeft = setTimeout(
            () => setShowTimeLeftSnack(true),
            diffMsTimeLeft > 2147483647 ? 2147483647 : diffMsTimeLeft,
          ));
    }
    if (user.employeeId === workroomDetailReservation?.owner?.id) {
      setAlertApproved(false);
      setAlertInProgress(false);
      ['Approved'].includes(workroomDetailReservation?.status) &&
        setApprovedResponse(true);
      ['InProgress'].includes(workroomDetailReservation?.status) &&
        setInProgressResponse(true);
    }
    return () => {
      clearTimeout(timerCheckin);
      clearTimeout(timerTmLeft);
    };
  }, [workroomDetailReservation]);

  const handleCheckOut = () => {
    dispatch(workroomActions.checkOutWorkroom(workroomDetailReservation.id));
  };

  const handleExtendReservation = async () => {
    try {
      const wr: Workroom = await reservationServices.extendReservation(
        workroomDetail.id,
        workroomDetailReservation.id,
        dayjs(workroomDetailReservation.endDate).tz().utc().toISOString(),
        dayjs(workroomDetailReservation.endDate)
          .tz()
          .utc()
          .add(maxTimeExtendRoom, 'minute')
          .toISOString(),
      );
      dispatch(reservationActions.setReservedWorkroom(wr));
      history.replace('/workstation/confirmation', {
        msg: t('msg_extend_success', { time: maxTimeExtendRoom }),
        router: '/dashboard/actions',
      });
      setShowTimeLeftSnack(false);
    } catch (error) {
      setShowTimeLeftSnack(false);
      setShowErrTimeRoom(true);
    }
  };

  const handleCheckin = () => {
    if (
      dayjs().tz() >
      dayjs(workroomDetailReservation.startDate)
        .tz()
        .add(maxTimeCheckInRoom, 'minute')
    ) {
      setShowToast(true);
    } else {
      dispatch(
        workroomActions.checkInWorkroom(
          workroomDetail.id,
          workroomDetailReservation.id,
          workroomDetailReservation?.owner?.id === user.employeeId,
        ),
      );
    }
  };

  const handleFilterPage = () => {
    dispatch(workroomActions.resetSearchFilters());
    history.push('/workroom/filters');
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            <IonButton onClick={() => history.push('/dashboard/actions')}>
              {t('close_text')}
            </IonButton>
          }
          title={t('detail_workroom')}
        />
      </IonHeader>
      <IonContent fullscreen>
        {showErrTimeRoom && (
          <Snackbar
            type="error"
            text={t('msg_err_snackbar_timeRoom')}
            icon="icon icon-close"
            align="left"
          />
        )}
        <div>
          <DetailCard
            id="map-detail-card"
            name="Sala"
            subName={workroomDetail?.descriptionType}
            type="workroom"
            icon="icon icon-room"
            title={`Sala ${workroomDetail?.name}`}
          ></DetailCard>
          <div className={styles.gridWeb}>
            <div className={styles.listGrid}>
              {workroomDetail.imageLink && (
                <Item lines="none" className={styles.imgContainer}>
                  <SafeImage
                    className={styles.imgStyle}
                    src={workroomDetail.imageLink}
                    fallback="/assets/images/workroom1.jpg"
                  />
                </Item>
              )}
              <DetailList>
                <DetailListItem
                  className={styles.itemList}
                  startIcon="icon icon-occupation"
                  startIconColor="primary"
                  title={t('lbl_capacity')}
                  description={t('lbl_min_members', {
                    qty: workroomDetail.capacity,
                  })}
                />

                {workroomDetail?.descriptionSede && (
                  <DetailListItem
                    startIcon="icon icon-explore"
                    startIconColor="primary"
                    title={t('lbl_head_office')}
                    description={workroomDetail?.descriptionSede}
                  />
                )}

                <DetailListItem
                  className={styles.itemList}
                  startIcon="icon icon-campus"
                  startIconColor="primary"
                  title={`${t('building_filter_workroom')} ${
                    workroomDetail.location?.buildingName
                  } , ${workroomDetail.location?.floorName}`}
                  description={
                    districtName === workroomDetail.location?.district ? (
                      <div className={styles.locatdivs}>
                        <Icon
                          color="primary"
                          className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
                        />
                        {t('lbl_your_district')}
                      </div>
                    ) : (
                      `${t('lbl_district')}: ${
                        workroomDetail.location?.district
                      }`
                    )
                  }
                />
              </DetailList>
              {workroomDetail.mapImage && (
                <MapReservation
                  x={workroomDetail.x}
                  y={workroomDetail.y}
                  mapImage={workroomDetail.mapImage}
                />
              )}
            </div>
            {(workroomDetailReservation &&
              workroomDetailReservation?.owner &&
              ['Approved'].includes(workroomDetailReservation?.status) && (
                <Alert
                  isOpen={alertApproved}
                  header="Reunión programada"
                  message={t('msg_alert_scan_qr_code_approve', {
                    name: workroomDetailReservation?.owner.name,
                    hour: dayjs(workroomDetailReservation.startDate)
                      .tz()
                      .format('HH:mm'),
                  })}
                  buttons={[
                    {
                      text: 'No',
                      role: 'cancel',
                      handler: () => setShowReservationBtn(true),
                    },
                    {
                      text: 'Sí',
                      handler: () => setApprovedResponse(true),
                    },
                  ]}
                  mode="ios"
                />
              )) ||
              (['InProgress'].includes(workroomDetailReservation?.status) && (
                <Alert
                  isOpen={alertInProgress}
                  header="Reunión en curso"
                  message={t('msg_alert_scan_qr_code_in_progress', {
                    name: workroomDetailReservation?.owner?.name,
                  })}
                  buttons={[
                    {
                      text: 'No',
                      role: 'cancel',
                      handler: () => setShowReservationBtn(true),
                    },
                    {
                      text: 'Sí',
                      handler: () => setInProgressResponse(true),
                    },
                  ]}
                  mode="ios"
                />
              ))}
          </div>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={2000}
        />
        <Toast
          isOpen={showToast}
          message={t('msg_checkin_error_toast')}
          position="bottom"
          type="error"
          onDidDismiss={() => setShowToast(false)}
        />
      </IonContent>
      {isPlatform('capacitor') && (
        <IonFooter id="map-detail-footer">
          {(!workroomDetailReservation?.owner || showReservationBtn) && (
            <div className={styles.btnGroupWeb}>
              <div>
                <Button color="lighter" onClick={handleFilterPage}>
                  <IonIcon slot="start" className="icon icon-room" />
                  {t('lbl_action_res_room')}
                </Button>
              </div>
            </div>
          )}
          {workroomDetailReservation?.owner && (
            <>
              {approvedResponse && (
                <div className={styles.btnGroupWeb}>
                  <div>
                    {['Approved'].includes(
                      workroomDetailReservation?.status,
                    ) && (
                      <Button onClick={handleCheckin} disabled={disableCheckIn}>
                        {t('lbl_button_checkin')}
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {inProgressResponse && (
                <div className={styles.btnGroupWeb}>
                  {showTimeLeftSnack &&
                    ['InProgress'].includes(
                      workroomDetailReservation?.status,
                    ) && (
                      <div>
                        <Button
                          color="lighter"
                          onClick={handleExtendReservation}
                        >
                          {t('lbl_button_addTime', { time: maxTimeExtendRoom })}
                        </Button>
                      </div>
                    )}
                  <div>
                    {['InProgress'].includes(
                      workroomDetailReservation?.status,
                    ) && (
                      <Button onClick={handleCheckOut}>
                        {t('lbl_button_checkout')}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </IonFooter>
      )}
    </IonPage>
  );
};

export default DetailWorkroom;
