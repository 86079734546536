import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useRef } from 'react';
import { SpinnerFullSize } from '../../../../../components/Base/SpinnerFullSize/SpinnerFullSize';
import { useLoadSafeImageUrl } from '../../../../../hooks/useLoadSafeImageUrl';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import CoverVerticalSlider from './components/CoverVerticalSlider';
import styles from './styles.module.scss';

type Props = {
  backgroundImageDesktop: string;
  backgroundImageMobile?: string;
  title: string;
  subtitle?: string;
  menuItems: any[];
  isLoading?: boolean;
};

export const Cover = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  title,
  subtitle,
  menuItems,
  isLoading = false,
}: Props) => {
  const contentPage = useRef<any>();
  const isMobile = useMediaQuery('(max-width: 376px)');

  const { data: background } = useLoadSafeImageUrl(
    isMobile && backgroundImageMobile
      ? backgroundImageMobile
      : backgroundImageDesktop,
    'black',
    { pre: 'url(', post: ')' },
  );

  return isLoading ? (
    <SpinnerFullSize />
  ) : (
    <IonPage
      className={styles.page}
      style={{
        background,
      }}
    >
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
        />
      </IonHeader>
      <IonContent className={styles.contentSty} ref={contentPage}>
        <div className={styles.contentWrapper}>
          <div
            className={`${styles.titleSection} ${styles.gridHeader} ${
              !subtitle ? styles.noSubtitle : ''
            }`}
          >
            <div className={styles.largeTitleBold}>{title}</div>
            <div
              className={`${styles.largeTitleLight} ${styles.largetittlePosition}`}
            >
              {subtitle}
            </div>
          </div>
          <CoverVerticalSlider
            menuItems={menuItems}
            itemDetailUrl={`/services/detail`}
            title={title}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
