import { Filesystem, Directory } from '@capacitor/filesystem';
import { HTTP } from '@awesome-cordova-plugins/http';
const downloadFileAsBase64 = async (url: string): Promise<string> => {
  try {
    const fileData = await HTTP.sendRequest(encodeURI(url), {
      method: 'get',
      responseType: 'arraybuffer',
    });

    const base64File = btoa(
      new Uint8Array(fileData.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '',
      ),
    );
    return base64File;
  } catch (error) {
    throw new Error(error);
  }
};

const fileExistInLocalStore = async (
  fileName: string,
): Promise<string | null> => {
  try {
    const uri = await Filesystem.getUri({
      directory: Directory.Data,
      path: fileName,
    });
    return uri.uri ? uri.uri : null;
  } catch (error) {
    throw new Error(error);
  }
};

const fileDelete = async (fileName: string): Promise<boolean> => {
  try {
    await Filesystem.deleteFile({
      directory: Directory.Data,
      path: fileName,
    });
    return true;
  } catch (error) {
    throw new Error(error);
  }
};

const saveFileToLocalStorage = async (
  file: string,
  fileName: string,
): Promise<string | null> => {
  try {
    await Filesystem.writeFile({
      path: fileName,
      data: file,
      directory: Directory.Data,
    });
    const localStorageFilePath = await fileExistInLocalStore(fileName);
    return localStorageFilePath;
  } catch (error) {
    throw new Error(error);
  }
};

const downloadFileAndReturnLocalPath = async (
  fileUrl: string,
  fileName: string,
): Promise<string> => {
  try {
    const base64File = await downloadFileAsBase64(fileUrl);
    return saveFileToLocalStorage(base64File, fileName);
  } catch (error) {
    throw new Error(error);
  }
};

export const FileSystemService = {
  downloadFileAsBase64,
  fileExistInLocalStore,
  downloadFileAndReturnLocalPath,
  fileDelete,
};
