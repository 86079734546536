import {
  Button,
  Icon,
  Item,
  Snackbar,
  Toast,
  TopBar,
} from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSpinner,
} from '@ionic/react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { UserStore } from '../../../../_redux/models/user.model';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { MapReservation } from '../../../../components/MapReservation/MapReservation';
import SafeImage from '../../../../components/SafeImage/SafeImage';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

const WorkroomPreReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const {
    workroomSelected,
    hosts,
    error,
    preselectedHost,
    reservNow,
    isLoading,
  } = useSelector((state: WorkroomStore) => state.workroom);
  useEffect(() => {
    dispatch(workroomActions.getAvailableHosts());
  }, []);

  const handleConfirmReservation = () => {
    dispatch(
      workroomActions.reserveWorkroom(
        workroomSelected.id,
        workroomSelected.preReservationDate.date,
        reservNow ? '' : workroomSelected.preReservationDate.from,
        workroomSelected.preReservationDate.to,
        preselectedHost?.employeeId,
      ),
    );
  };

  const handleAddHost = () => {
    history.push('/workroom/hostsearch', {
      preReservation: true,
    });
  };
  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('detail_reservation')}
        />
      </IonHeader>
      <IonContent fullscreen>
        {workroomSelected?.roomUseType === 'Interno' && (
          <div className={styles.warningSnackbar}>
            <Snackbar
              type="warning"
              text={t('lbl_snack_internal_use_workroom_warning')}
              icon="icon icon-incident"
              align="left"
            />
          </div>
        )}
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                className={styles.itemList}
                startIconColor="primary"
                startIcon="icon icon-booking"
                title={dayjs(workroomSelected.preReservationDate.date)
                  .tz()
                  .format('ddd DD MMM YYYY')}
                description={`${
                  reservNow
                    ? t('now')
                    : dayjs(workroomSelected.preReservationDate.from)
                        .tz()
                        .format('HH:mm')
                } - ${dayjs(workroomSelected.preReservationDate.to)
                  .tz()
                  .format('HH:mm')}`}
                readonly
              />
              {!isEmpty(hosts) && (
                <DetailListItem
                  className={styles.itemList}
                  lines="inset"
                  detail={false}
                  onClick={handleAddHost}
                  disabled={!isEmpty(preselectedHost)}
                  startIcon="icon icon-host"
                  startIconColor="primary"
                  titleLight={t('lbl_host')}
                  endIcon="icon icon-chevron-right"
                />
              )}
              {!isEmpty(preselectedHost) && (
                <DetailListItem
                  startIcon="icon icon-client"
                  startIconColor="primary"
                  titleLight={preselectedHost.fullName}
                  description={preselectedHost.function}
                  endIconAction="icon icon-delete"
                  actionEndIcon={() =>
                    dispatch(workroomActions.deletePreselectedHost())
                  }
                />
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-occupation"
                startIconColor="primary"
                title={t('lbl_capacity')}
                description={t('lbl_min_members', {
                  qty: workroomSelected.capacity,
                })}
                readonly
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-room"
                startIconColor="primary"
                title={`${t('workroom')} ${workroomSelected.name}`}
                description={workroomSelected.descriptionType}
                readonly
              />
              {workroomSelected.imageLink && (
                <Item lines="none" className={styles.imgContainer}>
                  <SafeImage
                    className={styles.imgStyle}
                    src={workroomSelected.imageLink}
                    fallback="/assets/images/workroom1.jpg"
                  />
                </Item>
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon icon-equipments"
                startIconColor="primary"
                title={t('lbl_equipment')}
                description={workroomSelected.equipment}
                readonly
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-explore"
                startIconColor="primary"
                title={t('lbl_head_office')}
                description={workroomSelected?.descriptionSede}
                readonly
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                startIconColor="primary"
                title={`${t('building_filter_workroom')} ${
                  workroomSelected.location?.buildingName
                } , ${workroomSelected.location?.floorName}`}
                description={
                  districtName === workroomSelected.location?.district ? (
                    <div className={styles.locatdivs}>
                      <Icon
                        color="primary"
                        className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
                      />
                      {t('lbl_your_district')}
                    </div>
                  ) : (
                    `${t('lbl_district')}: ${
                      workroomSelected.location?.district
                    }`
                  )
                }
                readonly
              />
            </DetailList>
            {workroomSelected.mapImage && (
              <MapReservation
                x={workroomSelected.x}
                y={workroomSelected.y}
                mapImage={workroomSelected.mapImage}
              />
            )}
          </div>
        </div>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleConfirmReservation} disabled={isLoading}>
              {t('btn_confirm_reservation')}
              {isLoading && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default WorkroomPreReservationDetail;
