import { Snackbar, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
} from '@ionic/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { ListCard } from '../../../../components/Base/ListCard';
import { useLocationState } from '../../../../hooks/useLocationState';
import styles from './styles.module.scss';

const ListWorkrooms: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useLocationState();

  const { workroomSelected, workrooms, searchFilters, reservNow, error } =
    useSelector((state: WorkroomStore) => state.workroom);
  const [workRoomData, setWorkRoomData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [date, setDate] = useState({
    date: new Date(),
    from: new Date(),
    to: new Date(),
  });

  useEffect(() => {
    error && setShowToast(true);
  }, [error]);

  useEffect(() => {
    if (workroomSelected?.preReservationDate) {
      setDate(
        workroomSelected.preReservationDate as {
          date: Date;
          from: Date;
          to: Date;
        },
      );
    } else {
      state && setDate(state as any);
    }
  }, [state, workroomSelected]);

  useEffect(() => {
    const dataRoom = [];
    workrooms &&
      workrooms.map(data => {
        const icons = [
          {
            id: 1,
            visible: true,
            type: 'label',
            icon: data.equipment,
            slot: 'start',
          },
          {
            id: 2,
            visible: true,
            type: 'icon',
            icon: 'icon icon-occupation',
            slot: 'end',
          },
          {
            id: 3,
            visible: true,
            type: 'label',
            icon: data.capacity.toString(),
            slot: 'end',
          },
        ];
        dataRoom.push({
          id: data.id,
          title: data.name,
          description: `${data.address} | ${data.floor}`,
          imageURL: data.imageLink,
          actions: icons,
          capacity: data.capacity,
        });
      });

    setWorkRoomData(dataRoom);
  }, [workrooms]);

  const handleSelected = id => {
    const wr = workrooms.find(wr => wr.id === id);
    wr.preReservationDate = date;
    dispatch(workroomActions.getReservableWorkroomDetail(wr));
  };
  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(workroomActions.resetErrorWorkroom());
  };

  const roomUseTypes = {
    Interno: t('use_internal'),
    Externo: t('use_external'),
  };

  const snackbarText = `${dayjs(date.date).tz().format('DD MMM YYYY')} ,
  ${reservNow ? t('now') : dayjs(date.from).tz().format('HH:mm')} -
  ${dayjs(date.to).tz().format('HH:mm')}                 
  ${
    searchFilters.capacity
      ? ', ' + t('lbl_people', { capacity: searchFilters.capacity })
      : ''
  }               
  ${
    searchFilters.roomUseType
      ? ', ' + roomUseTypes[searchFilters.roomUseType]
      : ''
  }               
  `;

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('select_workroom')}
        />
        <Snackbar type="info" text={snackbarText} align="center" />
      </IonHeader>
      <IonContent fullscreen>
        <Toast
          isOpen={showToast}
          onDidDismiss={handleDismissToast}
          message={error}
          position="bottom"
          type="error"
        ></Toast>
        <IonGrid className={styles.gridWeb}>
          <IonRow
            className={`ion-justify-content-center ${styles.rowNumberRooms}`}
          >
            <IonCol size="auto">
              <IonLabel color="primary" className={styles.lblNumberRooms}>
                {t('workromm_available', { number: workrooms.length })}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <IonRow className={styles.list}>
              {workRoomData &&
                workRoomData.map((wr, index) => {
                  return (
                    <IonCol
                      key={index}
                      size="12"
                      sizeSm="6"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="4"
                      className={styles.colAlignCenter}
                    >
                      <ListCard
                        className={styles.listCardWorkroom}
                        data={wr}
                        fallbackImg={'/assets/images/workroom1.jpg'}
                        onClick={() => {
                          handleSelected(wr.id);
                        }}
                      />
                    </IonCol>
                  );
                })}
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ListWorkrooms;
