import { IonText } from '@ionic/react';
import { FormatedText } from '../../../../../../components/Base/FormattedText';

import styles from './styles.module.scss';

export const ServiceDetailContent = ({
  name,
  details,
}: {
  name: string;
  details: string;
}) => {
  return (
    <>
      <IonText className={`${styles.titleSection} ion-text-wrap`}>
        {name}
      </IonText>
      <FormatedText className={styles.formatedText} content={details} />
    </>
  );
};

export default ServiceDetailContent;
