import { IonPage, IonRouterOutlet } from '@ionic/react';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ServiceCover from './pages/ServiceCover/ServiceCover';
import ServiceDetailView from './pages/ServiceDetailView/ServiceDetailView';

const ServicesRouter: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <PrivateRoute path="/services/:id">
          <ServiceCover />
        </PrivateRoute>
        <PrivateRoute path="/services/detail">
          <ServiceDetailView />
        </PrivateRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ServicesRouter;
