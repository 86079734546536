import muleSoftInstance from '../../../../_redux/utils/httpCommon';
import { GenericServiceData, MenuConfig } from './models';

enum EMoreEndPoints {
  GET_CONFIG_MENU = '/v1/others/menu',
  GET_SERVICE_DATA = '/v1/servicesConfig',
}

const getMoreMenuConfig = async (): Promise<MenuConfig> => {
  const path = `${process.env.REACT_APP_API_HABITAT}${EMoreEndPoints.GET_CONFIG_MENU}`;
  const res = (await muleSoftInstance.get(path)).data;
  return res;
};

const getServiceData = async (
  serviceId: number,
): Promise<GenericServiceData> => {
  const path = `${process.env.REACT_APP_API_HABITAT}${EMoreEndPoints.GET_SERVICE_DATA}?serviceId=${serviceId}`;
  const res = (await muleSoftInstance.get(path)).data;
  return res;
};

export const moreServices = {
  getMoreMenuConfig,
  getServiceData,
};
